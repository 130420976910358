<template>
  <video-wrapper :video="video" />
</template>

<script>
import VideoWrapper from '@/components/next/video-wrapper.vue';

export default {
  components: { VideoWrapper },

  data() {
    return {
      windowWidth: window.innerWidth,
      videoVariants: {
        sm: {
          src: '/videos/video_sm.mp4',
          preview: '/images/video_placeholder_xl.jpeg'
        },
        md: {
          src: '/videos/video_md.mp4',
          preview: '/images/video_placeholder_md.jpeg'
        },
        xl: {
          src: '/videos/video_xl.mp4',
          preview: '/images/video_placeholder_xl.jpeg'
        }
      }
    };
  },

  computed: {
    video() {
      if (this.windowWidth < 540) {
        return this.videoVariants.sm;
      } else if (this.windowWidth < 900) {
        return this.videoVariants.md;
      }
      return this.videoVariants.xl;
    }
  },

  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
  }
};
</script>
