<template>
  <div class="next-container">
    <section-base>
      <div class="heading-col">
        <h1 class="color-primary-100 mobile-h2">
          Die eprimo Strompakete kurz erklärt
        </h1>
      </div>

      <main-video />
    </section-base>

    <section-base>
      <accordion-faq>
        <div class="heading-col" slot="title">
          <h1 class="color-primary-100 mobile-h2">Haben Sie Fragen?</h1>
        </div>
      </accordion-faq>
    </section-base>
  </div>
</template>

<script>
import SectionBase from '@/components/next/section-base.vue';
import AccordionFaq from '@/components/next/accordion/accordion-faq.vue';
import MainVideo from '@/components/next/main-video.vue';

export default {
  components: { MainVideo, AccordionFaq, SectionBase }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
