<template>
  <div class="video">
    <div class="video-preview">
      <div class="play-button" @click="playVideo">
        <div class="play-icon"></div>
      </div>

      <figure @click="playVideo" class="picture" v-if="!startVideo">
        <img class="preview" :src="video.preview" alt="" />
      </figure>

      <div class="video-embed" v-else>
        <video
          autoplay
          :key="video.src"
          width="100%"
          controls
          style="border-radius: 10px;"
        >
          <source :src="video.src" type="video/mp4" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /**
   * video: {
   * src: string
   * preview: string
   * }
   */
  props: ['video'],

  data() {
    return {
      startVideo: false
    };
  },

  methods: {
    playVideo() {
      this.startVideo = !this.startVideo;
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
